import { createSlice } from '@reduxjs/toolkit';
import { COMMUNITY_CHANNELS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
import { createCommunityChannel } from './actions/createCommunityChannel';
const initialState = {
    adding: false,
    error: null,
    data: [],
    requestStatus: null,
    searchTerm: null,
};
const communityChannelsSlice = createSlice({
    name: COMMUNITY_CHANNELS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToCommunityChannelsStarted(state) {
            state.requestStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
        },
        subscribeToCommunityChannelsSucceeded(state, { payload }) {
            state.requestStatus = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToCommunityChannelsFailed(state, { payload }) {
            state.requestStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromCommunityChannels(state) {
            state.data = [];
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createCommunityChannel.pending, (state) => {
            state.adding = true;
        });
        builder.addCase(createCommunityChannel.fulfilled, (state) => {
            state.adding = false;
        });
        builder.addCase(createCommunityChannel.rejected, (state) => {
            state.adding = false;
        });
    },
});
export const { subscribeToCommunityChannelsStarted, subscribeToCommunityChannelsFailed, subscribeToCommunityChannelsSucceeded, unsubscribeFromCommunityChannels, } = communityChannelsSlice.actions;
export default communityChannelsSlice.reducer;
