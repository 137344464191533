import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { COMMUNITY_CHANNELS_REDUCER_NAME } from '../types';
import { parseChannelId, } from '../../../../models/documents/Community';
const insertChannelIntoContentTree = (contentTree, groupId, insertIndex, channel) => {
    const index = insertIndex ?? contentTree.length;
    if (!groupId) {
        return [...contentTree.slice(0, index), channel, ...contentTree.slice(index)];
    }
    const groupIndex = contentTree.findIndex((node) => node.id === groupId);
    if (groupIndex === -1) {
        return contentTree.map((node) => {
            if (node.type === 'group') {
                return {
                    ...node,
                    children: insertChannelIntoContentTree(node.children, groupId, index, channel),
                };
            }
            return node;
        });
    }
    return contentTree.map((node) => {
        if (node.id === groupId) {
            const group = node;
            return {
                ...group,
                children: [...group.children.slice(0, index), channel, ...group.children.slice(index)],
            };
        }
        return node;
    });
};
export const createCommunityChannel = createAsyncThunk(`${COMMUNITY_CHANNELS_REDUCER_NAME}/createCommunityChannel`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
        const community = await communityRef.get();
        const channelRef = communityRef.collection(COLLECTION.COMMUNITY_CHANNELS).doc();
        await channelRef.set({
            ...payload,
            createdAt: new Date(),
            updatedAt: new Date(),
            createdBy: user.uid,
            id: parseChannelId(channelRef.id),
        });
        const contentTree = community.data()?.contentTree || [];
        const updatedTree = insertChannelIntoContentTree(contentTree, payload.groupId, payload.index, {
            id: parseChannelId(channelRef.id),
            type: 'channel',
        });
        await communityRef.update({
            contentTree: updatedTree,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        console.error({ payload, error: e });
        return rejectWithValue(e);
    }
});
