import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { COMMUNITY_REDUCER_NAME } from '../types';
import { updateCommunityChannel } from '../../communityChannels/actions/updateCommunityChannel';
const findChannelsInTree = (tree, parentGroupId = null) => {
    const channelGroups = new Map();
    tree.forEach((node) => {
        if (node.type === 'channel') {
            channelGroups.set(node.id, parentGroupId);
        }
        else if (node.type === 'group') {
            const subGroups = findChannelsInTree(node.children, node.id);
            subGroups.forEach((groupId, channelId) => {
                channelGroups.set(channelId, groupId);
            });
        }
    });
    return channelGroups;
};
export const applyCommunityStructure = createAsyncThunk(`${COMMUNITY_REDUCER_NAME}/applyCommunityStructure`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth }, dispatch, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged in');
        }
        const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
        const communityDoc = await communityRef.get();
        const oldContentTree = communityDoc.data()?.contentTree || [];
        const oldChannelGroups = findChannelsInTree(oldContentTree);
        const newChannelGroups = findChannelsInTree(payload.updatedContentTree);
        for (const [channelId, newGroupId] of newChannelGroups) {
            const oldGroupId = oldChannelGroups.get(channelId);
            if (oldGroupId !== newGroupId) {
                dispatch(updateCommunityChannel({
                    payload: {
                        id: channelId,
                        groupId: newGroupId,
                        communityId: payload.communityId,
                    },
                }));
            }
        }
        await communityRef.update({
            contentTree: payload.updatedContentTree,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        console.error({ payload, error: e });
        return rejectWithValue(e);
    }
});
