import { ProductIntegrationClient } from '../../enums/ProductIntegrationClient';
export var AuthType;
(function (AuthType) {
    AuthType["BasicAuth"] = "basicAuth";
    AuthType["BearerToken"] = "bearerToken";
    AuthType["ApiKey"] = "apiKey";
    AuthType["None"] = "none";
})(AuthType || (AuthType = {}));
export var TaskType;
(function (TaskType) {
    TaskType["EmailClient"] = "emailClient";
    TaskType["DiscordIntegration"] = "discordIntegration";
    TaskType["ProductTask"] = "productTask";
    TaskType["CommunityTask"] = "communityTask";
})(TaskType || (TaskType = {}));
export var CommunityTask;
(function (CommunityTask) {
    CommunityTask["AddMember"] = "community.add_member";
    CommunityTask["ChangeMemberRole"] = "community.change_member_role";
    CommunityTask["DeactivateMember"] = "community.deactivate_member";
})(CommunityTask || (CommunityTask = {}));
export var EmailClientTask;
(function (EmailClientTask) {
    EmailClientTask["AddTags"] = "add_tags";
    EmailClientTask["RemoveTags"] = "remove_tags";
    EmailClientTask["AddToLists"] = "add_to_lists";
    EmailClientTask["RemoveFromLists"] = "remove_from_lists";
    EmailClientTask["AddFieldValues"] = "add_field_values";
})(EmailClientTask || (EmailClientTask = {}));
export var DiscordIntegrationTask;
(function (DiscordIntegrationTask) {
    DiscordIntegrationTask["AddToServer"] = "add_to_server";
    DiscordIntegrationTask["RemoveFromServer"] = "remove_from_server";
    DiscordIntegrationTask["AddRole"] = "add_role";
    DiscordIntegrationTask["RemoveRole"] = "remove_role";
})(DiscordIntegrationTask || (DiscordIntegrationTask = {}));
export var ProductTask;
(function (ProductTask) {
    ProductTask["ImportIntoProduct"] = "import_into_product";
    ProductTask["RemoveFromProduct"] = "remove_from_product";
})(ProductTask || (ProductTask = {}));
export var TaskCreator;
(function (TaskCreator) {
    TaskCreator["System"] = "system";
    TaskCreator["Owner"] = "owner";
})(TaskCreator || (TaskCreator = {}));
export var PriceFilterType;
(function (PriceFilterType) {
    PriceFilterType["Included"] = "included";
    PriceFilterType["Excluded"] = "excluded";
})(PriceFilterType || (PriceFilterType = {}));
export var EmbeddedFormColor;
(function (EmbeddedFormColor) {
    EmbeddedFormColor["RED"] = "red";
    EmbeddedFormColor["BLUE"] = "blue";
    EmbeddedFormColor["GREEN"] = "green";
    EmbeddedFormColor["YELLOW"] = "yellow";
    EmbeddedFormColor["GRAY"] = "gray";
    EmbeddedFormColor["INDIGO"] = "indigo";
    EmbeddedFormColor["PURPLE"] = "purple";
    EmbeddedFormColor["PINK"] = "pink";
})(EmbeddedFormColor || (EmbeddedFormColor = {}));
export var EmbeddedFormBackground;
(function (EmbeddedFormBackground) {
    EmbeddedFormBackground["TRANSPARENT"] = "transparent";
    EmbeddedFormBackground["LIGHTGRAY"] = "lightgray";
    EmbeddedFormBackground["WHITE"] = "white";
})(EmbeddedFormBackground || (EmbeddedFormBackground = {}));
export var ArchiveStrategyAccess;
(function (ArchiveStrategyAccess) {
    ArchiveStrategyAccess["Disabled"] = "disabled";
    ArchiveStrategyAccess["Unlimited"] = "unlimited";
    ArchiveStrategyAccess["FromSubscribeDate"] = "fromSubscribeDate";
})(ArchiveStrategyAccess || (ArchiveStrategyAccess = {}));
