export var ActionType;
(function (ActionType) {
    ActionType["ClientTerminated"] = "client_terminated";
    ActionType["ClientPurchaseProduct"] = "client_purchase_product";
    ActionType["ClientRenewedSubscription"] = "client_renewed_product";
    ActionType["ClientSubscriptionPlanUpdated"] = "client_subscription_plan_updated";
    ActionType["ClientRenewedAccessPeriod"] = "client_renewed_access_to_product";
    ActionType["NewsletterPaymentRequiredAction"] = "newsletter_payment_required_action";
    ActionType["NewsletterSubscriptionEnd"] = "newsletter_subscription_end";
    ActionType["NewsletterAccessPeriodEnd"] = "newsletter_access_period_end";
    ActionType["ClientsImportFinished"] = "clients_import_finished";
    ActionType["ClientCancelSubscriptionAtPeriodEnd"] = "client_cancel_subscription_at_period_end";
    ActionType["ClientResumeSubscriptionAtPeriodEnd"] = "client_resume_subscription_at_period_end";
    ActionType["NewsletterAccessPeriodEndingIn14Days"] = "newsletter_access_period_ending_in_14_days";
    ActionType["NewsletterAccessPeriodEndingIn7Days"] = "newsletter_access_period_ending_in_7_days";
    ActionType["NewsletterAccessPeriodEndingIn3Days"] = "newsletter_access_period_ending_in_3_days";
    ActionType["NewsletterAccessPeriodEndingIn1Day"] = "newsletter_access_period_ending_in_1_day";
    ActionType["NewsletterValidReferralPurchase"] = "newsletter_valid_product_referral";
    ActionType["NewsletterSubscriberGetReward"] = "newsletter_subscriber_get_reward";
    ActionType["ClientHasReferralRewardToConfirm"] = "client_has_referral_reward_to_confirm";
    ActionType["ClientGotReferralReward"] = "client_got_referral_reward";
    ActionType["VideoBeenUploaded"] = "video_been_uploaded";
    ActionType["ClientListProblem"] = "client_list_problem";
    ActionType["ClientTagProblem"] = "client_tag_problem";
})(ActionType || (ActionType = {}));
export var CommunityNotificationActionType;
(function (CommunityNotificationActionType) {
    CommunityNotificationActionType["NewPostAdded"] = "NewPostAdded";
    CommunityNotificationActionType["NewCommentAdded"] = "NewCommentAdded";
    CommunityNotificationActionType["NewReactionAdded"] = "NewReactionAdded";
    CommunityNotificationActionType["NewCommentReplyAdded"] = "NewCommentReplyAdded";
    CommunityNotificationActionType["NewCommentReactionAdded"] = "NewCommentReactionAdded";
    CommunityNotificationActionType["NewMentionAdded"] = "NewMentionAdded";
})(CommunityNotificationActionType || (CommunityNotificationActionType = {}));
export var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["READ"] = "read";
    NotificationStatus["UNREAD"] = "unread";
})(NotificationStatus || (NotificationStatus = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["ClientAction"] = "client_action";
    NotificationType["NewsletterInfo"] = "newsletter_info";
    NotificationType["CommunityAction"] = "community_action";
})(NotificationType || (NotificationType = {}));
