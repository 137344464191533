import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { COMMUNITY_GROUPS_REDUCER_NAME } from '../types';
export const deleteCommunityGroup = createAsyncThunk(`${COMMUNITY_GROUPS_REDUCER_NAME}/deleteCommunityGroup`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged in');
        }
        const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
        const community = await communityRef.get();
        const groupRef = communityRef.collection(COLLECTION.COMMUNITY_GROUPS).doc(payload.groupId);
        const channelsRef = db
            .collection(COLLECTION.COMMUNITIES)
            .doc(payload.communityId)
            .collection(COLLECTION.COMMUNITY_CHANNELS)
            .where('groupId', '==', payload.groupId);
        const channelsSnapshot = await channelsRef.get();
        const hasChannels = !channelsSnapshot.empty;
        if (hasChannels) {
            throw new Error('Cannot delete group with existing channels');
        }
        await db.runTransaction(async (transaction) => {
            transaction.delete(groupRef);
            const contentTree = community.data()?.contentTree || [];
            const updatedTree = contentTree.filter((node) => node.type !== 'group' || node.id !== payload.groupId);
            transaction.update(communityRef, {
                contentTree: updatedTree,
                updatedAt: new Date(),
            });
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        console.error({ payload, error: e });
        return rejectWithValue(e);
    }
});
