import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { COMMUNITY_CHANNELS_REDUCER_NAME } from '../types';
export const updateCommunityChannel = createAsyncThunk(`${COMMUNITY_CHANNELS_REDUCER_NAME}/updateCommunityChannel`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        const channelRef = db
            .collection(COLLECTION.COMMUNITIES)
            .doc(payload.communityId)
            .collection(COLLECTION.COMMUNITY_CHANNELS)
            .doc(payload.id);
        const { id, communityId, ...updateData } = payload;
        await channelRef.update({
            ...updateData,
            updatedAt: new Date(),
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        console.error({ payload, error: e });
        return rejectWithValue(e);
    }
});
