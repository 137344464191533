import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { COMMUNITY_GROUPS_REDUCER_NAME } from '../types';
export const updateCommunityGroup = createAsyncThunk(`${COMMUNITY_GROUPS_REDUCER_NAME}/updateCommunityGroup`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged in');
        }
        const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
        const groupRef = communityRef.collection(COLLECTION.COMMUNITY_GROUPS).doc(payload.id);
        const { id, communityId, ...updateData } = payload;
        await groupRef.update({
            ...updateData,
            updatedAt: new Date(),
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        console.error({ payload, error: e });
        return rejectWithValue(e);
    }
});
