import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { COMMUNITY_CHANNELS_REDUCER_NAME } from '../types';
const removeChannelFromContentTree = (contentTree, channelId) => {
    return contentTree
        .map((node) => {
        if (node.type === 'group' && node.children) {
            return {
                ...node,
                children: removeChannelFromContentTree(node.children, channelId),
            };
        }
        return node;
    })
        .filter((node) => node.type !== 'channel' || node.id !== channelId);
};
export const deleteCommunityChannel = createAsyncThunk(`${COMMUNITY_CHANNELS_REDUCER_NAME}/deleteCommunityChannel`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged in');
        }
        const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
        const community = await communityRef.get();
        const channelRef = communityRef
            .collection(COLLECTION.COMMUNITY_CHANNELS)
            .doc(payload.channelId);
        await db.runTransaction(async (transaction) => {
            transaction.delete(channelRef);
            const contentTree = community.data()?.contentTree || [];
            const updatedTree = removeChannelFromContentTree(contentTree, payload.channelId);
            transaction.update(communityRef, {
                contentTree: updatedTree,
                updatedAt: new Date(),
            });
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        console.error({ payload, error: e });
        return rejectWithValue(e);
    }
});
