import { createSlice } from '@reduxjs/toolkit';
import { COMMUNITY_GROUPS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    adding: false,
    error: null,
    data: [],
    requestStatus: null,
    searchTerm: null,
};
const communityGroupsSlice = createSlice({
    name: COMMUNITY_GROUPS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToCommunityGroupsStarted(state) {
            state.requestStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
        },
        subscribeToCommunityGroupsSucceeded(state, { payload }) {
            state.requestStatus = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToCommunityGroupsFailed(state, { payload }) {
            state.requestStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromCommunityGroups(state) {
            state.data = [];
            state.error = null;
        },
    },
});
export const { subscribeToCommunityGroupsStarted, subscribeToCommunityGroupsFailed, subscribeToCommunityGroupsSucceeded, unsubscribeFromCommunityGroups, } = communityGroupsSlice.actions;
export default communityGroupsSlice.reducer;
