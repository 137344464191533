export var CommunityMemberNotificationsStatus;
(function (CommunityMemberNotificationsStatus) {
    CommunityMemberNotificationsStatus["Enabled"] = "enabled";
    CommunityMemberNotificationsStatus["Disabled"] = "disabled";
})(CommunityMemberNotificationsStatus || (CommunityMemberNotificationsStatus = {}));
export var ActivityNotificationType;
(function (ActivityNotificationType) {
    ActivityNotificationType["CommentsOnMyPosts"] = "commentsOnMyPosts";
    ActivityNotificationType["ReplyToMyComments"] = "replyToMyComments";
    ActivityNotificationType["Mentions"] = "mentions";
    ActivityNotificationType["DirectMessages"] = "directMessages";
    ActivityNotificationType["ReactionToMyPosts"] = "reactionToMyPosts";
    ActivityNotificationType["ReactionToMyComments"] = "reactionToMyComments";
})(ActivityNotificationType || (ActivityNotificationType = {}));
export var ContentNotificationType;
(function (ContentNotificationType) {
    ContentNotificationType["NewPostInChannel"] = "newPostInChannel";
    ContentNotificationType["NewCourseContentReleased"] = "newCourseContentReleased";
})(ContentNotificationType || (ContentNotificationType = {}));
