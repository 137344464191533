import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMUNITY_MEMBERS_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const updateCommunityMember = createAsyncThunk(`${COMMUNITY_MEMBERS_REDUCER_NAME}/updateCommunityMember`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        const { communityId, id, ...updateData } = payload;
        console.log('updateData', payload);
        const memberRef = db
            .collection(COLLECTION.COMMUNITIES)
            .doc(communityId)
            .collection(COLLECTION.COMMUNITY_MEMBERS)
            .doc(id);
        await memberRef.update({
            ...updateData,
            updatedAt: new Date(),
        });
        analytics.track('update_community_member', {
            userId: user.uid,
            email: user.email,
            communityId,
            memberId: id,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
